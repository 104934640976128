/*
 * @Author: DY
 * @Date: 2021-06-10 10:18:53
 * @LastEditTime: 2021-09-17 17:24:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT.Web\src\views\salesBusiness\priceSetting\js\priceSetting.js
 * @function: 信息价通用方法脚本
 */
const priceSetting = Object.create(null);

/**
 * 判断有没有选择过  比较两个数组有没有重复键值
 * @param arr  数组
 * @param targetArr  目标数组
 * @param prop  数组比较的对象属性
 * @param targetProp    目标比较的数组对象属性
 * @param msgProp   重复提示消息
 * @returns {[]}
 */
priceSetting.checkRemoveRepeat = (_this, { arr, targetArr, prop, targetProp, msgProp }, ) => {
    // 先判断有没有已经选择过的
    const result = [];
    const haveSelectCode = [];// 已经选择的标识数组
    let msg = ''; // 重复提示消息
    if (arr && targetArr) {
        arr.forEach(selectItem => {
            haveSelectCode.push(selectItem[prop]);
        });
        targetArr.forEach(item => {
            let tarProps = '';
            if (targetProp === 'compose_code') {
                tarProps = [item[targetProp]];
            } else {
                tarProps = item[targetProp].split(',').sort().join();
            }
            if (tarProps.length > 1) {
                let haveRepeat = false;
                for (let str of haveSelectCode) {
                    str = str.split(',').sort().join();
                    if (tarProps === str) {
                        haveRepeat = true;
                        break;
                    }
                }
                if (haveRepeat) {
                    msg += item[msgProp] + ',';
                } else {
                    result.push(item);
                }
            } else if (haveSelectCode.indexOf(item[targetProp]) === -1) {
                result.push(item);
            } else {
                msg += item[msgProp] + ',';
            }
        });
    }
    if (msg) {
        msg = msg.substring(0, msg.length - 1);
        _this.$message({
            message: `${msg}已经选择过了~`,
            type: 'warning',
        });
    }
    return result;
};


export default priceSetting;