/*
 * @Author: DY
 * @Date: 2021-04-07 14:47:16
 * @LastEditTime: 2021-04-07 15:22:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT.Web\src\assets\js\decimals\index.js
 * @function: 四舍五入保留小数，toFixed不同浏览器，相同浏览器不同版本取值不一样
 */

const toFixedFun = (data, len) => {
    // 对数字末尾加0
    function padNum(num) {
        const dotPos = num.indexOf('.');
        if (dotPos === -1) {
        // 整数的情况
            num += '.';
            for (let i = 0; i < len; i++) {
                num += '0';
            }
            return num;
        }
        // 小数的情况
        const need = len - (num.length - dotPos - 1);
        for (let j = 0; j < need; j++) {
            num += '0';
        }
        return num;
    }
    const number = Number(data);
    if (isNaN(number) || number >= Math.pow(10, 21)) {
        return number.toString();
    }
    if (typeof len === 'undefined' || len === 0) {
        return Math.round(number).toString();
    }
    let result = number.toString();
    const numberArr = result.split('.');

    if (numberArr.length < 2) {
        // 整数的情况
        return padNum(result);
    }
    const intNum = numberArr[0]; // 整数部分
    const deciNum = numberArr[1];// 小数部分
    const lastNum = deciNum.substr(len, 1);// 最后一个数字

    if (deciNum.length === len) {
        // 需要截取的长度等于当前长度
        return result;
    }
    if (deciNum.length < len) {
        // 需要截取的长度大于当前长度 1.3.toFixed(2)
        return padNum(result);
    }
    // 需要截取的长度小于当前长度，需要判断最后一位数字
    result = `${intNum}.${deciNum.substr(0, len)}`;
    if (parseInt(lastNum, 10) >= 5) {
        // 最后一位数字大于5，要进位
        const times = Math.pow(10, len); // 需要放大的倍数
        let changedInt = Number(result.replace('.', ''));// 截取后转为整数
        changedInt++; // 整数进位
        changedInt /= times;// 整数转为小数，注：有可能还是整数
        result = padNum(`${changedInt}`);
    }
    return result;
};

export { toFixedFun };